import { computed } from "vue";

export function useMedia(mediaSrc) {
	const mediaUrl = computed(() => {
		return mediaSrc.value && typeof mediaSrc.value === "object"
			? mediaSrc.value.src
			: mediaSrc.value;
	});

	const isImage = computed(() => {
		return mediaUrl.value
			? mediaUrl.value.match(
					/\.jpg|\.jpeg|\.jfif|\.pjpeg|\.pjp|\.gif|\.png|\.svg|\.webp|^data:image\//i
			  )
			: null;
	});

	const isFile = computed(() => {
		return mediaUrl.value
			? mediaUrl.value.match(/\.csv|\.pdf|\.html|\.txt/i)
			: null;
	});

	const isBlobFile = computed(() => {
		return mediaUrl.value ? mediaUrl.value.includes("uploads") : false;
	});

	const isYoutube = computed(() => {
		return mediaUrl.value
			? mediaUrl.value.match(/https:\/\/www\.youtube\.com\/embed\/.*/)
			: null;
	});

	const isVimeo = computed(() => {
		return mediaUrl.value
			? mediaUrl.value.match(/https:\/\/player\.vimeo\.com\/video\/.*/)
			: null;
	});

	const isBrightcove = computed(() => {
		return mediaUrl.value
			? mediaUrl.value.match(/https:\/\/players\.brightcove\.net\/.*/)
			: null;
	});

	const isEMoney = computed(() => {
		return mediaUrl.value
			? mediaUrl.value.match(/https:\/\/abm\.emaplan\.com\/.*/)
			: null;
	});

	const isUploadedVideo = computed(() => {
		if (mediaUrl.value) {
			return (
				/\.(mp4|mov|avi|webm)(\?.*)?$/i.test(mediaUrl.value) &&
				isBlobFile.value
			);
		}
		return null;
	});

	const isVideo = computed(() => {
		return (
			isYoutube.value ||
			isVimeo.value ||
			isBrightcove.value ||
			isEMoney.value ||
			isUploadedVideo.value
		);
	});

	const videoAutoPlay = computed(() => {
		if (isVideo.value) {
			if (isYoutube.value)
				return "autoplay=1&mute=1&rel=0&iv_load_policy=3";
			return "autoplay=1&muted=1";
		}

		return "";
	});

	const videoLoop = computed(() => {
		if (isVideo.value) {
			if (isYoutube.value) {
				const videoId = mediaUrl.value.match(/\/embed\/([^?]*)/)[1];
				return `loop=1&playlist=${videoId}`;
			}

			return "loop=1";
		}

		return "";
	});

	const mediaType = computed(() => {
		if (isImage.value) {
			return "image";
		}
		if (isVideo.value) {
			return "video";
		}
		if (isFile.value) {
			return "file";
		}
		return null;
	});

	return {
		mediaUrl,
		isImage,
		isFile,
		isBlobFile,
		isYoutube,
		isVimeo,
		isBrightcove,
		isEMoney,
		isUploadedVideo,
		isVideo,
		mediaType,
		videoAutoPlay,
		videoLoop,
	};
}
